<template>
  <div class="appointmentList-view">
    <!-- 模块展示 -->
    <p class="fs9 fwbold mb20">模块展示</p>
    <div class="bgf5f6 pb20 pt20 pl10 flex-a-c mb20">
      <el-checkbox-group v-model="checkList" class="mr20">
        <el-checkbox label="商城模板" disabled></el-checkbox>
        <el-checkbox label="预约模板"></el-checkbox>
      </el-checkbox-group>
      <p class="lineheight0 fs6">
        可全选，可只展示“商城模块”
      </p>
    </div>
    <!-- 商城模块 -->
    <p class="fs9 fwbold mb20">商城模块</p>
    <div class="bgf5f6 pb20 pt20 pl10 flex-a-c mb20" v-if="currentType == 0">
      <p class="lineheight0 fs7 mr20">
        模块名称
      </p>
      <el-input type="text"
                class="w200 "
                v-model="shopName"
                maxlength="4"
                show-word-limit>
      </el-input>
    </div>

    <div class="d-flex mb20 pl10">
      <p class="fs7 mr20">
        模块样式
      </p>
      <div class="">
        <p class="fs7 mr20 fwbold mb20">
          当前样式：{{templateTitle}}
        </p>
        <el-button size="small" @click="templateDialog = true" type="primary">更换样式</el-button>
      </div>

    </div>
    <div v-if="currentType == 0 ">
      <div class="d-flex  pl10">
        <div class="fs7 mr10" style="width:70px">
          顶部轮播图
        </div>
        <div class="d-flex flex-wrap-y" style="width:1440px">

          <div class="bgf7fa pa10 d-flex align-items-center mr20 mb20 posre"  v-for="(item,index) in  mallBanner" :key="index">
            <div class="d-flex flex-column align-items-center ">
              <el-upload
                    accept="image/jpeg,image/png"
                    class="addTabIcon"
                    :action="uploadUrl"
                    :show-file-list="false"
                    :on-success="bannerSuccess.bind(null, {'index':index,'data':item})"
                    :before-upload="beforeUploadImageDynamicPic">
                <img v-if="item.img" :src="item.img" class="avatar dynamic">
                <i v-else class="el-icon-plus"></i>

            </el-upload>
            <p class="fs6">建议上传750px * 422px的图片效果最佳</p>
            </div>
            <jumpSet :title="item.title"
                    :index="index"
                    :currentGoods="item"
                    :goodsData="item"
                    @select_tap="select_tap"></jumpSet>
            <i class="el-icon-delete posab fs9 hover_pointer"
              v-if="index !== 0"
              @click="deleteImg(index)"
              style="bottom:10px;right:10px"></i>
            <el-button size="small"
                      type="primary"
                      class="posab"
                      style="right:-110px"
                      @click="addImg"
                      v-if="mallBanner.length - 1  == index &&  index !== 5">
              添加图片
            </el-button>
          </div>
        </div>

      </div>



    </div>
    <!-- 预约模块 -->
    <div v-if="!onlyStore">
      <p class="fs9 fwbold mb20">预约模块</p>
      <div class="bgf5f6 pb20 pt20 pl10 flex-a-c mb20">
        <p class="lineheight0 fs7 mr20">
          模块名称
        </p>
        <el-input type="text"
                  class="w200 "
                  v-model="reservationName"
                  maxlength="4"
                  show-word-limit>
        </el-input>
      </div>

      <div class="d-flex  pl10">
        <div class="fs7 mr10" style="width:70px">
          顶部轮播图
        </div>
        <div class="d-flex flex-wrap-y" style="width:1440px">

          <div class="bgf7fa pa10 d-flex align-items-center mr20 mb20 posre"  v-for="(item,index) in  appointmentBanner" :key="index">
            <div class="d-flex flex-column align-items-center ">
              <el-upload
                    accept="image/jpeg,image/png"
                    class="addTabIcon"
                    :action="uploadUrl"
                    :show-file-list="false"
                    :on-success="bannerSuccess.bind(null, {'index':index,'data':item,'type':'b'})"
                    :before-upload="beforeUploadImageDynamicPic">
                <img v-if="item.img" :src="item.img" class="avatar dynamic">
                <i v-else class="el-icon-plus"></i>

            </el-upload>
            <p class="fs6">建议上传750px * 422px的图片效果最佳</p>
            </div>
            <jumpSet :title="item.title"
                    :index="index"
                    :goodsData="item"
                    :type="'appointment'"
                    :currentGoods="item"
                    @select_tap="select_tap"></jumpSet>
            <i class="el-icon-delete posab fs9 hover_pointer"
              v-if="index !== 0"
              @click="deleteImg(index,'b')"
              style="bottom:10px;right:10px"></i>
            <el-button size="small"
                      type="primary"
                      class="posab"
                      style="right:-110px"
                      @click="addImg('b')"
                      v-if="appointmentBanner.length - 1  == index &&  index !== 5">
              添加图片
            </el-button>
          </div>
        </div>
      </div>

    </div>
    <div class="flex-j-c">
      <el-button size="small"
                type="primary"
                @click="saveImg">
            保存
      </el-button>
    </div>
    <el-dialog :title="'选择样式'"
               :center="true"
               width="80%"
               :modal-append-to-body="false"
               :visible="templateDialog"
               @close="templateDialogClose">
          <p class="fs8 mb20 text-align">您可根据选择以下任一一款样式装饰您的商城，除简约模式外，其他样式首次都需编辑后才可使用。</p>
          <div class="d-flex justify-content-between">
              <div class="d-flex hover_pointer flex-column"
                   style="height:600px "
                   v-for="(item,index) in storeTemplate"
                   @click="selectTemplate(item)"
                   :key="index">
                <div class="flex1 oyauto imgScroll shadow-gray50">
                  <img :src="item.img" class="w240" alt="">
                </div>
                <div class="bgfff text-align borderbox pt20" style="height:100px;">
                  <div class="h40" style="line-height: 40px;">
                    <div class="" style="line-height: 40px;">
                      <p class="fs9 cblue" v-if="index !== 0" @click.stop="editTemplate(item)">编辑内容</p>
                    </div>
                  </div>
                  <el-radio :label="item.title" size="medium" v-model="currentTitle" @change="goodsTypeRow(scope.$index,scope.row)"></el-radio>
                </div>
              </div>
          </div>
        <div class="d-flex justify-content-center">
           <el-button size="medium"
                      plain
                      class="mr100"
                      @click="cancel">
                        取消
            </el-button>
            <el-button size="medium"
                       type="primary"
                       @click="confirm">
                        确认
            </el-button>
        </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  queryShopModelConfigAll,
  queryShopConfig,
  saveShopConfig
} from "@/api/wechat";
import { getDataTimeSec } from "@/utils";
import jumpSet from "./components/jumpSet";
export default {
  data() {
    return {
      checkList:['商城模板','预约模板'],
      shopName:'产品商城', //商城模块名称
      reservationName:'预约服务', // 预约模块名称
      uploadUrl:this.$store.state.uploadingUrl,
      ossUrl:this.$store.state.ossUrl,
      mallBanner:[{
        img:"http://squ-one-stand.oss-cn-beijing.aliyuncs.com/product-index/20190418160834.png",
        title:"无"
      }],
      appointmentBanner:[{
        img:"http://squ-one-stand.oss-cn-beijing.aliyuncs.com/product-index/20190418160834.png",
        title:"无"
      }],
      storeTemplate:[{
        title:'简约模式',
        type:0,
        img:require('../../../src/static/bg1.jpg'),
      },{
        title:'综合电商',
        type:'ecommerce',
        img:require('../../../src/static/bg2.png')
      },{
        title:'节日优惠',
        type:'holiday_offer',
        img:require('../../../src/static/bg3.png')
      },{
        title:'日用百货',
        type:'daily_necessities',
        img:require('../../../src/static/bg4.png')
      },{
        title:'数码家电',
        type:'digital_appliances',
        img:require('../../../src/static/bg5.png')
      }],
      optionList:[
        {
          text: "无",
        },
        {
          text: "普通商品",
        },
        {
          text: "秒杀商品",
        },
        {
          text: "拼团商品",
        },
        {
          text: "分销商品",
        },
        {
          text: "名片页",
        },
        {
          text: "媒体页",
        },
        {
          text: "商城页",
        },
        {
          text: "动态页",
        },
        {
          text: "官网页",
        },
      ],
      templateDialog:false, // 商城模板
      currentTitle:'简约模式',
      currentType:0 ,
      templateTitle:'简约模式',
      templateType:0,
      onlyStore:false,
      allConfig:''
    };
  },
  components:{
    jumpSet,
  },
  watch:{
    checkList(newVal){
      if(newVal.length == 1){
        this.onlyStore = true
      }else{
        this.onlyStore = false
      }
    },
    mallBanner(newval){
      console.log(newVal)
    }
  },
  created(){
    this.queryConfig()
    this.queryShopConfigAll()
  },
  methods:{
    queryConfig(){
      let than = this
      let data = {
        ignore:1
      }
      queryShopConfig(data)
      .then(res=>{
        let than = this
        if(!res.data){
          return
        }
        if(JSON.parse(res.data.shopTop)){
          than.$set(than,'mallBanner', JSON.parse(res.data.shopTop))
        }
        if(JSON.parse(res.data.reservationTop)){
          than.$set(than,'appointmentBanner', JSON.parse(res.data.reservationTop))
        }
        than.shopName = res.data.shopName
        than.reservationName = res.data.reservationName
        if(res.data.showReservation){
          than.checkList = ['商城模板','预约模板']
        }else{
          than.checkList = ['商城模板']
        }
        than.currentType = res.data.shopTemplate
        than.storeTemplate.forEach(item=>{
          if(than.currentType == item.type){
            than.currentTitle = item.title
            than.templateTitle = item.title
          }
        })
      })
    },
    queryShopConfigAll(){
      let data = {
        ignore:true
      }
      queryShopModelConfigAll(data)
      .then(res=>{
        this.allConfig = res.data
      })
    },
    addImg(type){
      if(type !== 'b'){
        this.mallBanner.push({
          title:'无',
          img:'',
        })
      }else{
        this.appointmentBanner.push({
          title:'无',
          img:'',
        })
      }
    },
    deleteImg(index,type){
      if(type !== 'b'){
        this.mallBanner.splice(index,1)
      }else{
        this.appointmentBanner.splice(index,1)
      }
    },
    select_tap(index,val,type,goods,apptype){
      if(type !== 'appointment'){//商城

        this.mallBanner[index].title = val
        if(goods){
          this.mallBanner[index].goods = goods
          delete this.mallBanner[index].page
        }else{
          delete this.mallBanner[index].goods
          this.mallBanner[index].page = type
        }
      }else{//预约
        this.appointmentBanner[index].title = val
        if(goods){
          this.appointmentBanner[index].goods = goods
          delete this.appointmentBanner[index].page
        }else{
          delete this.appointmentBanner[index].goods
          this.appointmentBanner[index].page = apptype
        }
      }

    },
    bannerSuccess(obj,res,file){
      //动态图上传成功
      if(!obj.type){
        var index = obj.index;
        this.$set(this.mallBanner[index],'img',this.ossUrl + '/' + res.data)
      }else{
        var index = obj.index;
        this.$set(this.appointmentBanner[index],'img',this.ossUrl + '/' + res.data)
      }

    },
    beforeUploadImageDynamicPic(file){
      var _this = this;
      var isLt10M = file.size / 1024 / 1024  < 10;
      if (['image/jpeg', 'image/png'].indexOf(file.type) == -1) {
          _this.$message.error('请上传正确的图片格式');
          return false;
      }
      if (!isLt10M) {
          _this.$message.error('上传图片大小不能超过10MB哦!');
          return false;
      }
    },
    selectTemplate(item){
      this.currentTitle = item.title
      this.currentType = item.type
    },
    // 保存模板
    saveImg(){
      let than = this
      let marr = JSON.parse(JSON.stringify(than.mallBanner))
      let appArr = JSON.parse(JSON.stringify(than.appointmentBanner))
      // if(than.currentType == 0){
      //   if(!than.onlyStore){

      //   }else{
      //     appArr = ''
      //     than.reservationName = ''
      //   }
      // }else{
      //   appArr = ''
      //   than.reservationName = ''
      //   marr = ''
      //   than.shopName = ''
      // }
      let data = {
        reservationName:than.reservationName,
        reservationTop:JSON.stringify(appArr),
        shopTemplate:than.currentType,
        shopName:than.shopName,
        shopTop:JSON.stringify(marr),
        showShop:true,
        showReservation:!than.onlyStore,
      }
      saveShopConfig(data)
      .then(res=>{
        than.$message.success("保存成功")
      })
    },
    templateDialogClose(){
      this.templateDialog = false // 商城模板

    },
    //取消选择模板 恢复默认值
    cancel(){
      this.currentTitle = '简约模式'
      this.currentType = 0
      this.templateDialog = false
    },
    //确定模板
    async confirm(){
     let data = {
        ignore:true
      }
      let allConfig = await queryShopModelConfigAll(data)
      if( this.currentType !== 0){
        if(!allConfig.data){
          return this.$message.error('该模板需配置好内容后才可以保存，请点击“编辑内容”，配置好模板后再保存！')
        }
        this.allConfig = allConfig.data
        this.templateTitle = this.currentTitle
        this.templateType = this.currentType
        let isReturn = true
        this.allConfig.forEach(item=>{
          if(item.modelEnum == this.currentType){
            isReturn = false
          }
        })
        if(isReturn && this.currentType !== 0){
          return this.$message.error('该模板需配置好内容后才可以保存，请点击“编辑内容”，配置好模板后再保存！')
        }
      }


      this.templateDialog = false
    },
    editTemplate(item){
      this.currentTitle = item.title
      this.currentType = item.type

      switch (this.currentType) {
        case 'ecommerce':
          this.$router.push('/templateA'  )
          break;
        case 'holiday_offer':
          this.$router.push('/templateB'  )
          break;
        case 'daily_necessities':
          this.$router.push('/templateC'  )
          break;
        case 'digital_appliances':
          this.$router.push('/templateD'  )
          break;
        default:
          break;
      }

    },
  }
};
</script>

<style lang="scss" scoped>
.appointmentList-view{
  background: #fff;
  padding:30px;
}
 /deep/.addTabIcon .el-upload--text{
  width: 210px;
  height: 100px;
  line-height: 100px;
  margin-bottom: 10px;
}
/deep/ .addTabIcon .el-upload-list__item {
  width: 210px;
  height: 100px;
  line-height: 100px;

}
/deep/ .el-radio__inner{
  width: 20px;
  height: 20px;
}
/deep/ .el-radio__label{
  font-size: 16px;
}
.avatar{
  width: 100%;
  height: 100%;
}
.imgScroll::-webkit-scrollbar { width: 0 !important }
</style>
